<template>
  <v-container fluid class="invite px-0 px-sm-auto">
    <CategoryTitle :category="category" />

    <!-- CARD TESTATA -->

    <v-card
      class="invite-card default--text rounded-sm mt-2 mt-sm-7 mb-8"
      elevation="3"
    >
      <v-row no-gutters align="center" class="h-100">
        <v-col cols="12" sm="3">
          <v-img
            :src="inviteFriendImage"
            alt="Invita i tuoi amici"
            height="210"
            class="rounded-sm"
          />
        </v-col>

        <v-col cols="12" sm="9" class="pa-5 d-flex flex-column desc">
          <div
            class="text-body-1 text-sm-h4 font-weight-bold mb-2"
            v-html="inviteFriendTitle"
          ></div>
          <div
            class="text-body-2 text-sm-h5 font-weight-bold mb-2"
            v-html="inviteFriendDescription"
          ></div>
          <div class="text-caption text-sm-h5" v-html="inviteFriendText"></div>
        </v-col>
      </v-row>
    </v-card>

    <!-- CODICE SCONTO -->
    <v-row
      no-gutters
      class="personal-code pt-4 pb-11 default--text"
      justify="center"
      align="center"
      v-if="code"
    >
      <v-col cols="12" class="text-center">
        <div class="text-h3 font-weight-bold">
          {{ $t("invite.code.title") }}
        </div>
        <v-row
          no-gutters
          class="text-h3 font-weight-bold code-box rounded-sm py-3 mt-4 mx-auto"
          align="center"
        >
          <v-col cols="2">&nbsp;</v-col>
          <v-col cols="8">{{ personalCode.tellFriend.code }}</v-col>
          <v-col cols="2">
            <v-img
              src="/img_layout/copy.svg"
              width="25"
              height="30"
              @click="copyCode"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider />

    <!-- BUONI SCONTO -->
    <v-row no-gutters class="gift-certificates pt-7" v-if="giftCertificates">
      <v-col cols="12" class="text-center">
        <div class="text-h3 default--text font-weight-bold">
          {{ $t("invite.gifts.title") }}
        </div>
        <v-row
          no-gutters
          class="count-box text-h3 font-weight-bold rounded-sm py-6 mt-4 mx-auto default--text text--darken-2"
          align="center"
        >
          <v-col cols="12">
            <div class="count font-weight-bold">
              {{ giftCertificates.length }}
            </div>
            <div class="text-h4">
              {{ $tc("invite.gifts.friends", giftCertificates.length) }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters class="list my-11">
      <v-list dense class="w-100">
        <v-list-item v-for="(item, idx) in giftCertificates" :key="idx">
          <v-row justify="space-between" class="px-4">
            <div>
              <span>{{ item.name }}</span>
              <span v-if="item.amount">{{
                ` (${$n(item.amount, "currency")})`
              }}</span>
            </div>
            <!-- <div>{{ formatEndDate(item.endDate) }}</div> -->
          </v-row>
        </v-list-item>
      </v-list>
    </v-row>

    <v-divider />

    <!-- ISTRUZIONI -->

    <v-row
      no-gutters
      class="instructions pt-5 mb-16"
      v-if="loadInstructionSteps"
    >
      <v-col cols="12" class="text-center">
        <div class="text-h3 default--text font-weight-bold mb-5">
          {{ $t("invite.instructions.title") }}
        </div>
        <div class="slider invite-steps-slider">
          <swiper :options="swiperOption" ref="swiperRef">
            <swiper-slide
              v-for="(step, idx) in loadInstructionSteps"
              :key="idx"
            >
              <div class="instruction-box">
                <div
                  class="number d-box primary lighten-1 white--text rounded-xl"
                >
                  {{ idx + 1 }}
                </div>
                <div class="text-h4 font-weight-bold">
                  {{ step.title }}
                </div>
                <div class="text-body-1">
                  {{ step.description }}
                </div>
              </div></swiper-slide
            >
          </swiper>
          <div
            class="swiper-pagination"
            id="widget-slider-pagination-invite-steps"
          ></div>
          <div
            class="swiper-button-prev"
            id="widget-slider-prev-invite-steps"
          ></div>
          <div
            class="swiper-button-next"
            id="widget-slider-next-invite-steps"
          ></div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.invite {
  .invite-card {
    .row {
      max-width: 85%;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        max-width: 100%;
      }
    }
    .v-image {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .desc {
      div {
        line-height: 22px;
        @media #{map-get($display-breakpoints, 'xs-only')} {
          line-height: 18px;
        }
      }
    }
  }
  .personal-code {
    .code-box {
      border: 2px solid var(--v-primary-lighten1);
      max-width: 330px;
      .v-image {
        cursor: pointer;
      }
    }
  }
  .gift-certificates {
    .count-box {
      border: 1px solid $border-color;
      max-width: 330px;
      .count {
        font-size: 41px;
      }
    }
  }

  .list {
    .v-list-item:nth-child(odd) {
      background: var(--v-primary-lighten2);
    }
  }

  .instructions {
    .instruction-box {
      border: 1px solid $border-color;
      padding: 24px;
      min-height: 280px;
      @media #{map-get($display-breakpoints, 'sm-only')} {
        min-height: 330px;
      }
      .number {
        width: 51px;
        height: 51px;
        margin: 0 auto 12px;
        font-size: 30px;
        font-weight: bold;
      }
      .text-h4 {
        line-height: 28px;
        min-height: 75px;
      }
    }
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import category from "~/mixins/category";

import InviteService from "~/service/inviteService";
import get from "lodash/get";

export default {
  name: "Invite",
  components: { CategoryTitle },
  mixins: [category],
  data() {
    return {
      personalCode: null,
      instructions: null,
      swiperOption: {
        spaceBetween: 10,
        autoplay: false,
        watchOverflow: true,
        pagination: {
          el: "#widget-slider-pagination-invite-steps",
          clickable: true
        },
        navigation: {
          prevEl: "#widget-slider-prev-invite-steps",
          nextEl: "#widget-slider-next-invite-steps"
        },
        breakpoints: {
          600: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: 3
          }
        }
      }
    };
  },
  computed: {
    code() {
      return (
        this.personalCode &&
        this.personalCode.tellFriend &&
        this.personalCode.tellFriend.code
      );
    },
    loadInstructionSteps() {
      return JSON.parse(this.category.metaData.category_info.INVITE_STEP);
    },
    giftCertificates() {
      // let array = [
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "01/05/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "05/05/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "07/06/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "15/09/2022"
      //   }
      // ];
      // if (this.personalCode && this.personalCode.userGiftCertificates) {
      //   array.concat(
      //     this.personalCode && this.personalCode.userGiftCertificates
      //   );
      // }
      // return array;
      if (this.personalCode && this.personalCode.userGiftCertificates) {
        return this.personalCode && this.personalCode.userGiftCertificates;
      }
      return [];
    },
    inviteFriendTitle() {
      return get(this.category, "metaData.category_info.INVITE_BANNER_TITLE");
    },
    inviteFriendDescription() {
      return get(
        this.category,
        "metaData.category_info.INVITE_BANNER_DESCRIPTION"
      );
    },
    inviteFriendText() {
      return get(this.category, "metaData.category_info.INVITE_BANNER_TEXT");
    },
    inviteFriendImage() {
      return get(
        this.category,
        "metaData.category_info." +
          (this.$vuetify.breakpoint.xs
            ? "INVITE_IMAGE_MOBILE"
            : "INVITE_IMAGE_DESKTOP")
      );
    }
  },
  methods: {
    copyCode() {
      navigator.clipboard.writeText(this.personalCode.tellFriend.code);
      global.EventBus.$emit("success", {
        message: global.EventBus.$t("invite.code.copied")
      });
    },
    formatEndDate(date) {
      return this.$dayjs(date).format("D/MM/YYYY");
    }
  },
  async created() {
    let response = await InviteService.getPersonalCode();
    if (response) {
      this.personalCode = response;
    }
  }
};
</script>
